// ** Logo
import logo from '@src/assets/images/logo/logo.png'
import Loader from './Loading-spinner'
import { Columns } from 'react-feather'

const SpinnerComponent = () => {
  return (
    <div className='mb-5 fallback-spinner d-flex justify-content-center align-items-center vh-100'>
      <img className='mb-5 fallback-logo' src={logo} alt='logo' />
    {/* <div className='mt-5 fallback-spinner d-flex justify-content-center align-items-center'>
      <div className='ml-2 mt-5 loading component-loader'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div> */}
     <div className ='ml-2 mt-5'>
     <Loader/>
    </div>
    </div>
    )
}
export default SpinnerComponent
