import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { initializeApp } from "firebase/app"
import 'firebase/compat/messaging' 
import axios from 'axios' 

const askForPermissioToReceiveNotifications = async (registration) => {

    const firebaseConfig = {
        apiKey: "AIzaSyBgXvT3px7oPGVfc6dtwUbXV3gUm3Jixao",
        authDomain: "sooperappandroid.firebaseapp.com",
        databaseURL: "https://sooperappandroid.firebaseio.com",
        projectId: "sooperappandroid",
        storageBucket: "sooperappandroid.appspot.com",
        messagingSenderId: "783241626260",
        appId: "1:783241626260:web:34dd56edadcacb72e86a81",
        measurementId: "G-3JZZLJX2J0"
    }

    const app = initializeApp(firebaseConfig)
    
    try {
        const messaging = getMessaging(app)
        onMessage(messaging, (payload) => {
          console.log(payload)
                console.log(payload.notification)
                navigator.serviceWorker.getRegistration('./firebase-messaging-sw.js').then(registration => {
                    console.log("called")
                    registration.showNotification(
                        payload.notification.title,
                        {
                          data : payload.data.url,
                          icon : payload.data.icon,
                          image : payload.notification.image
                        }
                    )
                })
        })
         
        await Notification.requestPermission().then((callBack) => { 
        }).catch(e => {
        })
        await getToken(messaging, { vapidKey: 'BP8_SnkViDqfOXtooxQxMNiR7uKnc5VM3oFTv7FtKIesw3eQC8BneZQlX_ult71n5fbE-THy2ImLhoLmoaZPLCY'}).then((currentToken) => {
            if (currentToken) { 
                    console.log(currentToken)
                // now save that token in database
                    axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_SITE_URL}/api/auth/addnotifytoken`, 
                    data: {
                    token: currentToken,
                    userid: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')).id : 0
                    }})
                    .then(res => { 
                    console.log(res)
                    })
                    .catch(err => { 
                    }) 
            } else {
              console.log('No registration token available. Request permission to generate one.')
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
          }) 
    } catch (error) {
        console.error(error)
    }
}

export default askForPermissioToReceiveNotifications